import React, { useRef, useLayoutEffect } from "react";
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Formulario from "../components/Formulario"


import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import animateReveal from "../components/gs_reveal";


export default function ReformaChalets() {

  gsap.registerPlugin(ScrollTrigger);

  const revealRefs = useRef([]);
  revealRefs.current = [];

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    let scrollTriggers = [];
    scrollTriggers = animateReveal(revealRefs.current);
    return () => {
      scrollTriggers.forEach((t) => t.kill(true));
    };
  }, []);

  const addToRevealRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };
  // end gsap

  return (
    <Layout>
      <Helmet
        title="Empresa de reformas | Pide presupuesto ya"
        meta={[
          { name: 'description', content: 'Reformas.Top es una empresa de reformas integrales. Pide presupuesto gratis y sin compromiso.' },
        ]}
      />
      <div className="bg-[url('https://reformas.top/background/reformas-de-chalets.webp')] bg-fixed bg-cover bg-bottom bg-no-repeat">
        <div className=" container flex flex-col xl:flex-row justify-between py-5">
          <div className=" flex items-center justify-center 2xl:px-10">
            <h1 className="text-white text-center xl:text-left text-4xl tracking-tight sm:text-6xl lg:text-6xl 2xl:text-7xl leading-[1.2] mb-10">
              <strong>Reformas de Chalets</strong>: adosados, pareados y unifamiliares en
            </h1>
          </div>
          <div>
            <Formulario />
          </div>
        </div>
      </div>

      <section id="costo-reforma">
        <div className="container  mb-20">
          <div className="lg:max-w-5xl mx-auto mb-20">
            <h2 className="text-4xl lg:text-6xl text-center lg:leading-normal	">¿Cuánto cuesta la reforma integral de una
              casa?
            </h2>
          </div>
          <div className="mb-20">
            <StaticImage src="../images/reforma-integral-de-una-casa.webp" alt="¿Cuánto cuesta la reforma integral de una casa?" className=" mx-auto" />
          </div>
          <div className=" lg:max-w-5xl mx-auto text-center">
            <p>
              Reformar tu propiedad requerirá de un trabajo amplio e integral, donde diversos factores <strong>podrán
                aumentar o
                disminuir el coste final de tu inversión</strong>. Con nuestro servicio de reformas en ciudad tienes la
              garantía de
              contratar una empresa profesional y altamente calificada para ofrecerte lo que realmente estás buscando.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="container  mb-20 ">
          <div className="flex flex-col-reverse lg:flex-row items-center lg:w-11/12 mx-auto">
            <div className="basis-1/2 bg-gray-bg bg-opacity-25 lg:rounded-l-3xl p-5 lg:p-10">
              <h2 className="mb-5">Reformar una casa para venderla</h2>
              <p>Podrás reestructurar tu propiedad para hacerla mucho más competitiva en el mercado inmobiliario. Para
                esto
                contarás con la ayuda de expertos que podrán asesorarte en las últimas tendencias en este tipo de
                propiedades. Esta es una forma de inversión con un atractivo grado de ganancia, pues estarás ofreciendo un
                espacio mucho más propicio y tentador para la gran mayoría de compradores.
              </p>
            </div>
            <div className="basis-1/2 mb-5">
              <StaticImage src="../images/reformar-casa-para-venderla.webp" alt="Reformar una casa para venderla" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mb-20 ">
          <div className="flex flex-col lg:flex-row items-center  lg:w-11/12 mx-auto">
            <div className="basis-1/2 mb-5">
              <StaticImage src="../images/reformar-casa-para-alquilarla.webp" alt="Reformar una casa para alquilarla" />
            </div>
            <div className="basis-1/2 bg-gray-bg bg-opacity-25 lg:rounded-r-3xl p-5 lg:p-10">
              <h2 className="mb-5">Reformar una casa para alquilarla</h2>
              <p>En estos casos podremos revisar el estado general de tu casa, vigilando muy bien el suministro e
                instalación de servicios básicos, así como dotándola de la funcionalidad adecuada que necesita un inmueble
                para ser alquilado y que perdure mucho más en el tiempo. Así tus posibles o futuros inquilinos tendrán un
                espacio cómodo, seguro y con las características precisas. Esto también aplica para todo tipo de reformas
                de
                adosados en ciudad.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-10"
        style={{ "background": "transparent linear-gradient(0deg, #f2f4f9 0%, #FFFFFF00 30%) 0% 0% no-repeat padding-box" }}>
        <div className="container  ">
          <div className=" grid grid-cols-1 lg:grid-cols-2 gap-20 mb-20">
            <div className="flex items-center">
            <StaticImage src="../images/presupuestos-para-reformas-casas.webp" alt="¿Cómo son las reformas de las casas?" />
            </div>
            <div>
              <h2 className="mb-5">¿Cómo son las reformas de las casas?</h2>
              <p>Ciertamente, son mucho más complejas que los trabajos en otros tipos de inmuebles, como las Reformas de
                locales comerciales, sobre todo por cuestiones de
                espacio. Para iniciar, se realizará un diagnóstico del estado actual de tu casa, de esta forma se podrá
                hacer un presupuesto específico con todos los aspectos a considerar.</p>
              <p>
                En la mayoría de trabajos se toman en cuenta diseños, cubiertas, cerramientos y acabados, así como los
                trabajos de albañilería, fontanería y carpintería. También se vigila muy bien todo lo relacionado con
                servicios básicos, ventilación y pintura.
                Todo esto con los mejores materiales y procedimientos del sector.</p>
              <p> De esta manera te aseguramos las mejores reformas de pareados en ciudad, o cualquier tipo de inmueble
                que tengas.</p>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-5 gap-10">
            <div className="col-span-3 items-center flex">
              <h2 className="mb-5 text-6xl">¿Cómo reducir el precio en nuestra reforma?</h2>
            </div>
            <div className="col-span-2">
              <p>Recuerda que las reformas baratas no existen, y no debes caer en empresas sin escrúpulos que te prometen
                enormes proyectos con muy bajos presupuestos. </p>
              <p>En su lugar, puedes pensar en préstamos o subvenciones. Por
                nuestra parte, te ayudaremos a ahorrar todo lo posible en materiales y diseño. Así lograrás las reformas
                de chalets en ciudad que tanto deseas.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mb-20 mt-20 ">
          <h2 className="text-4xl lg:text-6xl text-center mb-5 ">Presupuestos para reformas casas</h2>
          <div className=" lg:max-w-5xl mx-auto text-center">
            <p>Para hacer reformas de villas en ciudad, o cualquier tipo de inmueble, te ofreceremos un cálculo de
              presupuesto preciso, con todas las variables a considerar y un pequeño margen para posibles modificaciones.
              Este servicio también aplica para las reformas de pisos. Podrás leer más acerca de este servicio en el
              siguiente enlace: https://reformas.top/pisos/ciudad</p>
          </div>
        </div>
        <div className="flex flex-col-reverse lg:flex-row items-center lg:w-11/12 mx-auto container">
          <div className="basis-1/2 p-5 lg:p-10">
            <h2 className="mb-5">Presupuestos para reformas casas</h2>
            <p>Para hacer reformas de villas en ciudad, o cualquier tipo de inmueble, te ofreceremos un cálculo de
              presupuesto preciso, con todas las variables a considerar y un pequeño margen para posibles modificaciones.
              Este servicio también aplica para las reformas de pisos. Podrás leer más acerca de este servicio en el
              siguiente enlace: https://reformas.top/pisos/ciudad
            </p>
          </div>
          <div className="basis-1/2 flex justify-center mb-5">
            <StaticImage src="../images/reformas-baratas-casas.webp" alt="Presupuestos para reformas casas" />
          </div>
        </div>

      </section>
    </Layout>
  )
}